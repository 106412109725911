var render = function render() {
  var _setup$orderCustomTex, _setup$lineItem, _setup$lineItem2, _setup$lineItem2$lp;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.productTemplate ? _c('SvgTextEditor', {
    ref: "svgEditor",
    attrs: {
      "value": (_setup$orderCustomTex = _setup.orderCustomTexts) !== null && _setup$orderCustomTex !== void 0 ? _setup$orderCustomTex : {},
      "svg": _setup.productTemplate,
      "qr-url": (_setup$lineItem = _setup.lineItem) === null || _setup$lineItem === void 0 ? void 0 : _setup$lineItem.url,
      "logo": _setup.logoUrl,
      "gift-url": (_setup$lineItem2 = _setup.lineItem) === null || _setup$lineItem2 === void 0 ? void 0 : (_setup$lineItem2$lp = _setup$lineItem2.lp) === null || _setup$lineItem2$lp === void 0 ? void 0 : _setup$lineItem2$lp.lp_url,
      "raw": ""
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };